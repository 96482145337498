import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/router/router";
import store from "@/store";
import { fireAuth } from "@/services/firebase";

Vue.config.productionTip = false;

let app;
fireAuth.onAuthStateChanged(async (user) => {
    if (user) {
        await store.dispatch("session/initializeSession", user);
    } else {
        await store.dispatch("session/resetSession");
    }
    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            render: (h) => h(App)
        }).$mount("#app");
    }
});
