import { fireAuth, db } from "@/services/firebase";
import { doc, getDoc } from "firebase/firestore";

const getDefaultState = () => {
    return {
        userInfo: {
            userId: "",
            name: "",
            role: "",
            admin: {
                id: "",
                name: "",
            },
            coach: {
                id: "",
                name: "",
            },
            coachee: {
                id: "",
                name: "",
            },
            navItems: [],
        },
        bottomSheet: {
            message: "",
            isVisible: false,
            color: "info",
            success: null,
        },
        navigation: {
            navItems: [
                {
                    id: 1,
                    text: "Start",
                    routeName: "Home",
                    //icon: "mdi-view-dashboard-outline",
                    icon: "mdi-home-outline",

                },
                {
                    id: 2,
                    text: "Coachees",
                    routeName: "Coachees",
                    icon: "mdi-alpha-c-circle-outline",
                    roles: ["admin", "coach"],
                },
                {
                    id: 3,
                    text: "Interviews",
                    routeName: "Surveys",
                    icon: "mdi-alpha-i-circle-outline",
                },
                {
                    id: 4,
                    text: "Admin",
                    icon: "mdi-cogs",
                    children: [
                        {
                            id: 401,
                            label: "Coaches",
                            routeName: "Coaches",
                            roles: ["admin"],
                        },
                        {
                            id: 402,
                            label: "Beheerders",
                            routeName: "Administrators",
                            roles: ["admin"],
                        },
                        {
                            id: 403,
                            label: "Interview templates",
                            routeName: "SurveyTemplates",
                            roles: ["admin"],
                        },
                        {
                            id: 404,
                            label: "Users",
                            routeName: "Users",
                            roles: ["admin"],
                        },

                    ],
                },

            ],
        },
    };
};
// STATE
const state = getDefaultState();

// ACTIONS
const actions = {
    async resetSession(context) {
        Object.assign(state, getDefaultState());
    },
    async initializeSession(context, user) {
        context.state.userInfo.userId = user.uid;
        context.state.userInfo.name = user.displayName;

        // GET USER ROLE FROM CLAIMS
        const idTokenResult = await user.getIdTokenResult();
        context.state.userInfo.role = idTokenResult.claims.role;

        switch (context.state.userInfo.role) {
            case "admin":
                const adminRef = doc(
                    db,
                    "administrators",
                    idTokenResult.claims.id
                );
                const adminSnap = await getDoc(adminRef);
                if (adminSnap.exists()) {
                    context.state.userInfo.admin.id = adminSnap.id;
                    context.state.userInfo.admin.name =
                        adminSnap.data().name.firstName +
                        " " +
                        adminSnap.data().name.lastName;
                }
                break;
            case "coach":
                const coachRef = doc(db, "coaches", idTokenResult.claims.id);
                const coachSnap = await getDoc(coachRef);
                if (coachSnap.exists()) {
                    context.state.userInfo.coach.id = coachSnap.id;
                    context.state.userInfo.coach.name =
                        coachSnap.data().name.firstName +
                        " " +
                        coachSnap.data().name.lastName;
                }
                break;
            case "coachee":
                const coacheeRef = doc(db, "coachees", idTokenResult.claims.id);
                const coacheeSnap = await getDoc(coacheeRef);
                if (coacheeSnap.exists()) {
                    context.state.userInfo.coachee.id = coacheeSnap.id;
                    context.state.userInfo.coachee.name =
                        coacheeSnap.data().name.firstName +
                        " " +
                        coacheeSnap.data().name.lastName;
                }
                break;
        }

        // BUILD NAVIAGTION FROM ROLE
        context.state.userInfo.navItems =
            context.state.navigation.navItems.filter(function f(_item) {
                if (_item.hasOwnProperty("children")) {
                    return (_item.children = _item.children.filter(f)).length;
                }
                if (_item.hasOwnProperty("roles")) {
                    let _idx = _item.roles.indexOf(context.state.userInfo.role);
                    return _idx >= 0;
                } else return true;
            });
    },
    showBottomMessage: (context, message) => {
        var timeout = 5000;
        context.commit("SET_BOTTOM_MESSAGE", message);
        context.commit("SET_BOTTOM_SUCCESS", null);
        context.commit("SHOW_BOTTOM_MESSAGE", true);
        window.setTimeout(function () {
            context.commit("SHOW_BOTTOM_MESSAGE", false);
        }, timeout);
    },
    showSuccess: (context) => {
        var timeout = 2500;
        context.commit("SET_BOTTOM_MESSAGE", "");
        context.commit("SET_BOTTOM_SUCCESS", true);
        context.commit("SHOW_BOTTOM_MESSAGE", true);
        window.setTimeout(function () {
            context.commit("SHOW_BOTTOM_MESSAGE", false);
        }, timeout);
    },
};
// MUTATIONS
const mutations = {
    SET_BOTTOM_MESSAGE: (state, pMessage) => {
        state.bottomSheet.message = pMessage;
    },
    SET_BOTTOM_SUCCESS: (state, pSuccess) => {
        state.bottomSheet.success = pSuccess;
    },
    SHOW_BOTTOM_MESSAGE: (state, pShow) => {
        state.bottomSheet.isVisible = pShow;
    },
};
// GETTERS
const getters = {
    bottomSheet: (state) => {
        return state.bottomSheet;
    },
    sessionActive: (state) => {
        return state.userInfo.userId && state.userInfo.role;
    },
    userInfo: (state) => {
        return state.userInfo;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
