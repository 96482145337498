import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import Home from "@/views/Home";
import goTo from "vuetify/lib/services/goto";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/resetPassword",
        name: "ResetPassword",
        component: lazyLoad("ResetPassword"),
        meta: { requiresAuth: false },
    },
    {
        path: "/logout",
        name: "Logout",
        component: Logout,
        meta: { requiresAuth: true },
    },
    {
        path: "/coaches",
        name: "Coaches",
        component: lazyLoad("Coaches"),
        meta: { requiresAuth: true },
    },
    {
        path: "/coachees",
        name: "Coachees",
        component: lazyLoad("Coachees"),
        meta: { requiresAuth: true },
    },
    {
        path: "/surveys",
        name: "Surveys",
        component: lazyLoad("Surveys"),
        meta: { requiresAuth: true },
    },
    {
        path: "/surveys/:id",
        name: "Survey",
        component: lazyLoad("Survey"),
        meta: { requiresAuth: true },
    },
    {
        path: "/grid/:id",
        name: "Grid",
        component: lazyLoad("Grid"),
        meta: { requiresAuth: true },
    },
    {
        path: "/admin/questions",
        name: "Questions",
        component: lazyLoad("Questions"),
        meta: { requiresAuth: true },
    },
    {
        path: "/admin/questions/:id",
        name: "Question",
        component: lazyLoad("Question"),
        meta: { requiresAuth: true },
    },
    {
        path: "/admin/surveyTemplates",
        name: "SurveyTemplates",
        component: lazyLoad("SurveyTemplates"),
        meta: { requiresAuth: true },
    },
    {
        path: "/admin/Administrators",
        name: "Administrators",
        component: lazyLoad("Administrators"),
        meta: { requiresAuth: true },
    },
    {
        path: "/admin/Users",
        name: "Users",
        component: lazyLoad("Users"),
        meta: { requiresAuth: true },
    },
    {
        path: "/Peers/:id",
        name: "Peers",
        component: lazyLoad("Peers"),
        meta: { requiresAuth: true },
    },
    {
        path: '/surveys/:surveyId/peers/:peerId',
        name: "PeerReviewInternal",
        component: lazyLoad("PeerReviewInternal"),
        meta: { requiresAuth: true },
    },
    {
        path: "/peer-review",
        name: "PeerReview",
        component: lazyLoad("PeerReview"),
        meta: { requiresAuth: false }
    }
];

function lazyLoad(view) {
    return () => {
        return import(`@/views/${view}.vue`);
    };
}

const scrollBehavior = function (to, from, savedPosition) {
    let scrollTo = 0;
    if (to.hash) {
        scrollTo = to.hash;
    } else if (savedPosition) {
        scrollTo = savedPosition.y;
    }
    return setTimeout(function () {
        goTo(scrollTo);
    }, 1000);
};

const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior,
});

// N A V I G A T I O N   G U A R D
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters["session/sessionActive"]) {
            next();
        } else {
            next({ name: "Login" });
        }
    } else {
        // if no auth required
        next();
    }
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || process.env.VUE_APP_TENANT_NAME;
    });
});

export default router;
