import Vue from "vue";
import Vuex from "vuex";
import session from "@/store/modules/session";
import surveys from "@/store/modules/surveys";

Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        session,
        surveys,
    },
});
export default store;
