<style scoped>
	.cntnr {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
<template>
	<v-progress-circular
		class="cntnr"
		color="primary"
		size="96"
		indeterminate
	/>
</template>
<script>
	/* eslint-disable */
	import { fireAuth } from "@/services/firebase";
	import { signOut } from "firebase/auth";
	export default {
		name: "Logout",
		mounted() {
			const that = this;
			window.setTimeout(function () {
				that.logout();
			}, 1500);
		},
		methods: {
			logout() {
				signOut(fireAuth)
					.then(() => {
						window.location.reload();
					})
					.catch((error) => {
						// An error happened.
						console.log(error);
					});
			},
		},
	};
</script>
