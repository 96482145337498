<template>
  <div>

  <v-container>
    <h1 style="text-align:center; color:#0e7c94;">De gedragsvoorkeuren</h1>
  
  
   <br><br>

 
			<span>Beste {{ userInfo.name }}, </span>
		

    <br><br>
    
    <p>1. Ga naar de pagina "Interviews". Je vindt de link terug aan de linkerzijde van je scherm. Hier vind je je interview terug dat je loopbaancoach heeft klaargezet.</p>
    <p>2. Je zal zien dat de status staat op "NEW". </p>
    <p>3. Selecteer "VRAGENLIJST" om je bevraging te starten. </p>
    <br><br>
    <p>Lees de stelling grondig. Vind je jezelf terug in de stelling, zet dan je voorkeur op 5. Vind je je helemaal niet terug in de stelling, 
      selecteer dan 1.
      Is het een soms wel, soms niet gevoel, selecteer dan 3.
    </p>
    <p>Vind je dat je wat in de stelling wordt omschreven volledig onder de knie hebt, dan scoor je een hoge aanwezigheid. 
      Vind je dat je het helemaal niet beheerst, dan kies je voor laag.
    </p>
    <br>
    <p>Er zijn 40 stellingen waar je op moet antwoorden. Na de 40ste stelling kan je de bevraging afsluiten. 

    </p>
    <p style="text-align:center;"><img src="https://fulfil.be/wp-content/uploads/gedrag.png" > </p>

  
  </v-container>
</div>

</template>

<script>
import { mapGetters } from "vuex";
/* eslint-disable */
export default {
  name: 'Home',
  computed: {
              ...mapGetters({
				      userInfo: "session/userInfo"
			        })
            }
}

</script>
