<template>
	<v-list nav flat dense>
		<div v-for="navItem in navItems" :key="navItem.id">
			<v-list-item
				v-if="!navItem.children"
				router
				:to="{ name: navItem.routeName }"
				exact
				active-class="font-weight-medium  primary--text"
			>
				<v-list-item-icon>
					<v-icon v-text="navItem.icon"></v-icon>
				</v-list-item-icon>
				<v-list-item-title v-text="navItem.text" />
			</v-list-item>
			<v-list-group v-else :prepend-icon="navItem.icon" no-action>
				<template v-slot:activator>
					<v-list-item-title v-text="navItem.text" />
				</template>
				<v-list-item
					v-for="_navItem in navItem.children"
					:key="_navItem.id"
					router
					:to="{ name: _navItem.routeName }"
					active-class="font-weight-medium primary--text"
				>
					<v-list-item-title v-text="_navItem.label" />
				</v-list-item>
			</v-list-group>
		</div>
	</v-list>
</template>

<script>
	export default {
		name: "SmartNavBar",
		props: {
			navItems: Array,
		},
	};
</script>
