import { db } from "@/services/firebase";
import { collection, getDocs } from "firebase/firestore";

// STATE
const state = {
    surveys: [],
};
// ACTIONS
const actions = {
    fetchSurveys: async (context) => {
        /*
        const cRef = collection(db, "surveys");
        const response = await getDocs(cRef);
        const surveys = response.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
        });
        context.commit("setSurveys", surveys);
*/
    },
};

// MUTATIONS
const mutations = {
    setSurveys: (state, payload) => {
        state.surveys = payload;
    },
};

// GETTERS
const getters = {
    surveyList(state) {
        return state.surveys.sort((a, b) => b.createdAt - a.createdAt);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
