<style scoped>
.bg {
	height: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.cntnr {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>

<template>
	<div class="bg" v-if="!sessionActive">
		<v-card height="400px" width="400px" min-height="50vh" max-height="70vh" max-width="90vw"
			class="cntnr d-flex flex-column justify-space-between">
			<v-sheet>
				<v-img src="@/assets/logo.png" height="52" contain position="center center" />
				<v-card-subtitle class="text-center text-h5">{{ appName }}</v-card-subtitle>
				<v-card-subtitle class="text-center">Inloggen met je email adres en wachtwoord</v-card-subtitle>
			</v-sheet>
			<v-card-text>
				<v-form @keyup.native.enter="submitLogin">
					<v-text-field v-model="userEmail" type="text" placeholder="Gebruiker" clearable
						prepend-icon="mdi-account-circle" single-line hide-details />
					<v-text-field v-show="!signInWithLink" v-model="userPwd" :type="showPassword ? 'text' : 'password'"
						placeholder="Wachtwoord" single-line hide-details prepend-icon="mdi-lock"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						@click:append="showPassword = !showPassword" />
					<p v-if="error" class="red--text text-center my-4">
						{{ errMsg }}
					</p>
				</v-form>
			</v-card-text>
			<v-sheet>
				<v-card-actions>
					<v-btn block :loading="loading" color="primary" @click.stop="submitLogin">
						Aanmelden
					</v-btn>
				</v-card-actions>
				<v-card-actions>
					<v-btn x-small block plain @click.stop="resetPassword">Wachtwoord vergeten?</v-btn>
				</v-card-actions>
			</v-sheet>
		</v-card>
	</div>
</template>

<script>
/* eslint-disable */
import { fireAuth } from "@/services/firebase";
import {
	isSignInWithEmailLink,
	signInWithEmailLink,
	signInWithEmailAndPassword,
} from "firebase/auth";
import { mapGetters } from "vuex";

export default {
	name: "Login",
	data: () => ({
		signInWithLink: false,
		signInLink: "",
		userEmail: "",
		userPwd: "",
		showPassword: false,
		error: false,
		errMsg: "",
		loading: false,
		appName: process.env.VUE_APP_APP_NAME,
		tenantName: process.env.VUE_APP_TENANT_NAME,
	}),
	created() {
		if (isSignInWithEmailLink(fireAuth, window.location.href)) {
			this.signInWithLink = true;
			this.signInlink = window.location.href;
		}
	},
	computed: {
		...mapGetters({
			sessionActive: "session/sessionActive",
		}),
	},
	methods: {
		async submitLogin() {
			this.loading = true;
			try {
				if (this.signInWithLink) {
					await signInWithEmailLink(
						fireAuth,
						this.userEmail,
						this.signInlink
					);
				} else {
					await signInWithEmailAndPassword(
						fireAuth,
						this.userEmail,
						this.userPwd
					);
				}
				this.$router.push({ name: "Home" });
			} catch (err) {
				this.error = true;
				this.errMsg = "Dit is een ongeldige login: " + err.message;
			}
			this.loading = false;
		},
		resetPassword() {
			this.$router.push({ name: "ResetPassword" });
		},
	},
};
</script>

