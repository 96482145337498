import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

let firebaseConfig = {}

// init app
if (process.env.VUE_APP_ENV === 'development') {
    firebaseConfig = {
        apiKey: "AIzaSyCiTdA_KgQObc3D9EalBcjbuxZSjVsafmU",
        authDomain: "fulfil-dev.firebaseapp.com",
        projectId: "fulfil-dev",
        storageBucket: "fulfil-dev.appspot.com",
        messagingSenderId: "569097486092",
        appId: "1:569097486092:web:622312bfa7ae7d655f9329",
        measurementId: "G-X7Z88XV1YS"
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyBPZMT8s7lajWcMx5f1CVfwBYjhtWM4zqg",
        authDomain: "gedragsvoorkeuren.firebaseapp.com",
        projectId: "gedragsvoorkeuren",
        storageBucket: "gedragsvoorkeuren.appspot.com",
        messagingSenderId: "940026165784",
        appId: "1:940026165784:web:2ec60e58aa08029fe7616d",
        measurementId: "G-SZL0YD6FP2",
    };
}

const app = initializeApp(firebaseConfig);
const fireAuth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);

// add auth to app
export { fireAuth, db, functions, storage };
