<template>
	<v-app id="app">
		<!-- NAVDRAWER  -->
		<v-navigation-drawer
			style="z-index: 1"
			v-model="drawer"
			v-if="sessionActive"
			app
			:mini-variant="miniVariant"
			floating
			class="grey lighten-4"
		>
			<v-img
				class="ml-4"
				src="@/assets/logo.png"
				height="52"
				contain
				position="left center"
			/>

			<smart-nav-bar
				class="text-subtitle-1"
				:navItems="userInfo.navItems"
			/>
		</v-navigation-drawer>

		<!-- APP BAR  -->
		<v-app-bar app v-if="sessionActive" flat dense class="white">
			<v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
			<v-spacer />
			<span> {{ userInfo.name }} - {{ userInfo.role }} </span>
			<v-btn icon plain :to="{ name: 'Logout' }">
				<v-icon>mdi-logout</v-icon>
			</v-btn>
		</v-app-bar>

		<!-- MAIN -->
		<v-main>
			<transition name="fade" mode="out-in">
				<keep-alive>
					<router-view> </router-view>
				</keep-alive>
			</transition>
		</v-main>

		<!-- BOTTOM SHEET -->
		<v-bottom-sheet
			v-model="bottomSheet.isVisible"
			hide-overlay
			scrollable
			width="auto"
		>
			<v-sheet v-if="bottomSheet.success"
				><v-btn icon large @click="bottomSheet.isVisible = false"
					><v-icon color="success">mdi-thumb-up</v-icon></v-btn
				></v-sheet
			>
			<v-card v-else class="mx-auto" max-height="150px" dark>
				<v-card-title>
					<v-spacer />
					<v-btn right icon @click="bottomSheet.isVisible = false"
						><v-icon>mdi-close-circle-outline</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="text-center">
					{{ bottomSheet.message }}
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</v-app>
</template>

<script>
	import { mapGetters } from "vuex";
	import SmartNavBar from "./components/SmartNavBar.vue";

	export default {
		name: "App",
		components: { SmartNavBar },
		data() {
			return {
				drawer: null,
				miniVariant: false,
				scroller: false,
				tenantName: process.env.VUE_APP_TENANT_NAME,
				appName: process.env.VUE_APP_APP_NAME,
			};
		},
		computed: {
			...mapGetters({
				sessionActive: "session/sessionActive",
				bottomSheet: "session/bottomSheet",
				userInfo: "session/userInfo",
			}),
		},
		methods: {
			toggleDrawer() {
				if (this.$vuetify.breakpoint.mobile) {
					if (!this.drawer) {
						this.drawer = true;
						this.miniVariant = false;
					} else {
						this.drawer = false;
					}
				} else {
					this.miniVariant = !this.miniVariant;
				}
			},
		},
	};
</script>

<style>
	/*** SCROLLBAR ***/
	/* width */
	::-webkit-scrollbar {
		width: 8px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: #eceff1; /* blue-grey lighten-5  */
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #b0bec5; /* blue-blue-grey lighten-5  */
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #78909c; /* blue-grey lighten-1 */
	}

	/*** TRANSITIONS ***/
	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.75s ease-out;
	}

	.fade-leave-to {
		opacity: 0;
	}

	.slide-fade-enter {
		transform: translateX(10px);
		opacity: 0;
	}

	.slide-fade-enter-active,
	.slide-fade-leave-active {
		transition: all 0.25s ease;
	}

	.slide-fade-leave-to {
		transform: translateX(-10px);
		opacity: 0;
	}

	.kmp {
		position: fixed;
		left: 50%;
		transform: translate(-50%, 0%);
	}
</style>
